import { html, LitElement } from 'lit';
import { property } from 'lit/decorators.js';
import { styleMap } from 'lit/directives/style-map.js';
import { trackPrintError } from './utils/tracking';
import ChevronRight from './icon-chevron';
import { sponsorAltText } from './utils/sponsor-alt';

const ViewabilityBreakingChangesVersion = '1.0.0';

const trackCall = ({ url }: TrackUrl) => {
  fetch(url, { method: 'GET' })
    .then((response: Response) => {
      if (![200, 204].includes(response.status)) {
        throw new Error(`Fetch failed with status code ${response.status}`);
      }
    })
    .catch((error: TypeError) => trackPrintError(error?.message, url));
};

export default class BaseBanner extends LitElement {
  connectedCallback() {
    super.connectedCallback();
    this.sendViewTrack();
  }

  protected firstUpdated() {
    try {
      const event = new Event('adn-banner-loaded', { bubbles: true, composed: true });
      this.dispatchEvent(event);

      if (this.print_url) {
        const printUrls: Array<TrackUrl> = JSON.parse(this.print_url);
        printUrls.forEach(trackCall);
      }
    } catch (error) {
      trackPrintError('Error on first update');
    }
  }

  @property({ type: String })
  primary_title = '';

  @property({ type: String })
  secondary_title = '';

  @property({ type: String })
  cta = '';

  @property({ type: String })
  destination_url = '';

  @property({ type: String })
  picture = '';

  @property({ type: String })
  card_background_color = '';

  @property({ type: String })
  card_text_color = '';

  @property({ type: String })
  volanta = '';

  @property({ type: String })
  legal = '';

  @property({ type: String })
  primary_logo = '';

  @property({ type: String })
  secondary_logo = '';

  @property({ type: String })
  print_url = '';

  @property({ type: Array })
  click_url = [];

  @property({ type: String })
  view_url = '';

  sendViewTrack() {
    if (this.view_url) {
      try {
        const viewUrls: Array<TrackUrl> = JSON.parse(this.view_url);
        this.createViewabilitySession(viewUrls);
      } catch (e) {
        trackPrintError('Error on connectedCallback', this.view_url);
      }
    }
  }

  isViewabilityLegacy = () => window.viewability && window.viewability.version !== ViewabilityBreakingChangesVersion;

  isViewabilityLoaded = () => window.viewability && window.viewability.getNonceToken() !== '';

  createViewabilitySession(viewUrls: Array<TrackUrl>) {
    const startViewabilitySession = () => {
      if (!window.viewability) {
        return;
      }
      const session = window.viewability.addSession();
      session.setMeliVaTrackUrl(viewUrls[0].url);
      session.start(this.parentElement);
    };

    if (this.isViewabilityLegacy()) {
      window.viewability.addSession(this.parentElement, viewUrls[0].url);
      return;
    }
    if (this.isViewabilityLoaded()) {
      startViewabilitySession();
      return;
    }
    window.addEventListener('viewability-enabled', startViewabilitySession);
  }

  handlelink(clickUrlName = 'click_url', deeplinkName = 'destination_url') {
    const click_url = this.getClickUrl(clickUrlName);

    if (typeof window !== 'undefined' && window.MobileWebKit) {
      window.MobileWebKit.navigation.push(this[deeplinkName as keyof this], { replace: false })
        .then(() => trackCall(click_url))
        .catch(() => {
          trackCall(click_url);
          window.location = this[deeplinkName as keyof this] as unknown as Location;
        });
    } else {
      window.location.href = click_url?.url;
    }
  }

  getClickUrl(clickUrlName:string) {
    const [click] = this[clickUrlName as unknown as keyof this] as unknown as Array<TrackUrl>;
    return click;
  }


  renderImage(className: string, src: string) {
    const altKey = Object.keys(sponsorAltText).find((key: string) => src.includes(key));
    let altText = '';
    if (altKey) {
      altText = sponsorAltText[altKey];
    }

    return html`
      <img class=${className} src=${src} alt=${altText} />
    `;
  }

  renderChevron() {
    return html`
      <div class="adn-cta-chevron">${ChevronRight(this.card_text_color)}</div>
    `;
  }

  renderBottomContent() {
    const legal = html`<p class="adn-text-legal adn-text-format">${this.legal}</p>`;
    const cta = html`
      <div class="adn-cta ${this.volanta ? 'with-volanta' : 'without-volanta'}">
        <p class="adn-text-cta adn-text-format">${this.cta}</p>
        ${this.cta && this.renderChevron()}
      </div>
    `;
    return this.legal ? legal : cta;
  }

  renderContentTexts() {
    const volanta = html`<p class="adn-text-volanta">${this.volanta}</p>`;
    const legal = html`<p class="adn-text-legal adn-text-format">${this.legal}</p>`;

    const cta = html`
      <div class="adn-cta ${this.volanta ? 'with-volanta' : 'without-volanta'}">
        <p class="adn-text-cta adn-text-format">${this.cta}</p>
        ${this.renderChevron()}
      </div>
    `;

    return html`
      <div class="adn-container-texts"
        style=${styleMap({ color: this.card_text_color, background: this.card_background_color })}
      >
        <div class=${this.volanta ? 'with-volanta' : ''}>
          ${this.volanta ? volanta : html``}
          <p class="adn-text-title adn-text-format">${this.primary_title}</p>
          <p class="adn-text-title adn-text-format">${this.secondary_title}</p>
        </div>
        ${this.legal ? legal : cta}
      </div>
    `;
  }

  renderLogos() {
    return html`
      <div class="adn-container-logo">
        <div class="position-logo">
          ${this.primary_logo && this.renderImage('adn-logo', this.primary_logo)}
          ${this.secondary_logo && this.renderImage('adn-logo', this.secondary_logo)}
        </div>
      </div>
    `;
  }

  renderContentLandscape() {
    const volanta = html`<p class="adn-text-volanta">${this.volanta}</p>`;

    return html`
      <div class="adn-container-texts"
        style=${styleMap({ color: this.card_text_color, background: this.card_background_color })}
      >
      ${this.primary_logo && this.renderLogos()}
        <div class="adn-content-text ${this.volanta ? 'with-volanta' : 'without-volanta'}">
          ${this.volanta ? volanta : html``}
          <p class="adn-text-title adn-text-format">${this.primary_title}</p>
          <p class="adn-text-title adn-text-format">${this.secondary_title}</p>
          ${this.renderBottomContent()}
        </div>
      </div>
    `;
  }

  renderContentVertical() {
    return html`
      <div class="adn-container-texts"
        style=${styleMap({ color: this.card_text_color, background: this.card_background_color })}
      >
        <div class="adn-content-text ${this.primary_logo ? 'with-logo' : 'without-logo'}">
          <div class="${this.volanta ? 'with-volanta' : ''}">
            <p class="${this.volanta ? 'adn-text-volanta' : 'adn-text-without-volanta'}">${this.volanta}</p>
            <p class="adn-text-title adn-text-format">${this.primary_title}</p>
            <p class="adn-text-title adn-text-format">${this.secondary_title}</p>
          </div>
          ${this.renderBottomContent()}
        </div>
      </div>
    `;
  }
}
